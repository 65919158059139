import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  StyleRules,
  SwipeableDrawer,
  Tab,
  Tabs,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { navigate } from 'gatsby';

import { adminDeleteProjectRequest } from '../../../actions/admin/adminActions';
import { adminGetAllProjectRequest } from '../../../actions/projectRequestAction';
import { CancelCompanyPublishProjectDialog, GeneralDialog } from '../../../components/Dialog';
import DialogTitle from '../../../components/DialogTitle';
import { AppState } from '../../../reducers';
import { FProjectRequestStatusEnum } from '../../../reducers/projectRequestReducer';
import CITY from '../../../utils/city';
import cityChild from '../../../utils/cityChild';
import ApplyingBiddingInfoView from '../../consultant/applyingBiddingInfoView';
import BidScreening from '../../consultant/bidScreening';
import PublishProjectView from '../../consultant/publishProjectView';

const styles = (): StyleRules =>
  createStyles({
    DataGridMargin: {
      minHeight: '80vh',
    },
    contentPadding: {
      padding: '10px 0',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      // minHeight: '80vh',
      paddingBottom: '48px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    DialogWidth: {
      minWidth: '850px',
      border: '20px solid red',
    },
    searchInput: {
      margin: '20px',
    },
    backgroundColor: {
      backgroundColor: '#ffffff',
    },
    textTitle: {
      textAlign: 'center',
    },
    multiple: {
      margin: '1rem',
    },
  });

const companyProjectListView = ({ classes }: Props): ReactElement => {
  FProjectRequestStatusEnum;
  const dispatch = useDispatch();
  const projectListReducer = useSelector((appState: AppState) => appState.projectListReducer);
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [projectRowData, setProjectRowData] = useState<ProjectRequestInfo[]>([]);
  const [value, setValue] = useState(0);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [projectRequestId, setProjectRequestId] = useState<number>();
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: '项目名称',
      // editable: true,
      flex: 1,
    },
    {
      field: 'workScenario',
      headerName: '工作方式',
      width: 150,
      renderCell: params => {
        const workScenario = JSON.parse(params.row.workScenario);
        return `${workScenario.onsite ? '在线' : ''}，${workScenario.remote ? '远程' : ''}`;
      },
    },
    {
      field: 'workLocationCity',
      headerName: '工作地点',
      type: 'number',
      width: 200,
      renderCell: params => {
        return `${CITY.find(city => city.code === params.row.workLocationCity)?.name}-${
          params.row.workLocationDistrict
            ? cityChild.find(cityChild => cityChild.code === params.row.workLocationDistrict)?.name
            : ''
        }`;
      },
    },
    {
      field: 'actions',
      headerName: '操作',
      type: 'number',
      width: 300,
      sortable: false,
      renderCell: params => {
        return (
          <>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.buttonMargin}
              onClick={() => {
                const state = value == 0 ? { examine: true, closed: true } : { closed: true };

                navigate('/project/view/' + '#' + params.row.id, {
                  state,
                });
              }}
            >
              <VisibilityIcon /> 查看
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.buttonMargin}
            >
              <NotInterestedIcon /> 拒绝
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.buttonMargin}
              onClick={() => {
                setProjectRequestId(params.row.id);
                setIsDeleteOpen(true);
              }}
            >
              <NotInterestedIcon /> 删除
            </Button>
          </>
        );
      },
    },
  ];

  const handleAdminDeleteRequest = async () => {
    if (projectRequestId) {
      const value = await dispatch(adminDeleteProjectRequest(projectRequestId));
      if (value) {
        setIsDeleteOpen(false);
      }
      fetchData();
    }
  };
  const [filters, setFilters] = useState<FilterDateType>();

  const handleFilterChange = (newFilters: FilterDateType) => {
    setFilters(newFilters);
  };
  filters;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    await dispatch(adminGetAllProjectRequest());
  };

  const [searchValue, setSearchValue] = useState('');

  const findItems = () => {
    if (searchValue) {
      const filteredData = projectRowData.filter((item: ProjectRequestInfo) => {
        if (item.projectName) {
          return item.projectName.includes(searchValue);
        } else {
          return false;
        }
      });
      setProjectRowData([...filteredData] as []);
    }
  };

  const projectOnChange = (Event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(Event.target.value);
    if (!Event.target.value) {
      filterData();
    }
  };

  const filterData = (): void => {
    if (projectListReducer && projectListReducer.projectList?.length > 0) {
      switch (value) {
        case 0: {
          const newPublishedList = projectListReducer.projectList.filter(
            item => item.status === 'SUBMITTED' || item.status === 'EDITING'
          );
          setProjectRowData([...newPublishedList] as []);
          break;
        }
        case 1: {
          const newPublishedList = projectListReducer.projectList.filter(
            item => item.status === 'PUBLISHED' || item.status === 'NONE'
          );
          setProjectRowData([...newPublishedList] as []);
          break;
        }
        case 2: {
          const newPublishedList = projectListReducer.projectList.filter(
            item => item.status === 'FINISHED'
          );
          setProjectRowData([...newPublishedList] as []);
          break;
        }
        default:
          setProjectRowData([...projectListReducer.projectList] as []);
          break;
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (projectListReducer && projectListReducer.projectList?.length > 0) {
      switch (value) {
        case 0: {
          const newPublishedList = projectListReducer.projectList.filter(
            item => item.status === 'SUBMITTED' || item.status === 'EDITING'
          );
          setProjectRowData([...newPublishedList] as []);
          break;
        }
        case 1: {
          const newPublishedList = projectListReducer.projectList.filter(
            item => item.status === 'PUBLISHED' || item.status === 'NONE'
          );
          setProjectRowData([...newPublishedList] as []);
          break;
        }
        case 2: {
          const newPublishedList = projectListReducer.projectList.filter(
            item => item.status === 'FINISHED'
          );
          setProjectRowData([...newPublishedList] as []);
          break;
        }
        default:
          setProjectRowData([...projectListReducer.projectList] as []);
          break;
      }
    }
  }, [projectListReducer]);

  useEffect(() => {
    filterData();
  }, [value]);

  return (
    <div className={classes.contentPadding}>
      <GeneralDialog
        isOpen={isDeleteOpen}
        titleText="删除项目请求"
        handleDialogClose={() => {
          setIsDeleteOpen(false);
        }}
        handleEdit={() => {
          handleAdminDeleteRequest();
        }}
        contentText="确认要删除项目么"
      />
      <Helmet title="企业项目管理"></Helmet>
      <SwipeableDrawer
        anchor="right"
        open={DrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <BidScreening
          onChangefilter={handleFilterChange}
          className={classes.searchInput}
        ></BidScreening>
      </SwipeableDrawer>
      <Grid container xs={12}>
        <Grid item xs={6} container>
          <TextField
            className={`${classes.searchInput} ${classes.backgroundColor}`}
            id="outlined-search"
            label="查询项目"
            fullWidth={true}
            type="search"
            variant="outlined"
            value={searchValue}
            onChange={projectOnChange}
          />
        </Grid>
        <Grid item xs={3} container>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.searchInput}
            onClick={findItems}
          >
            搜索
          </Button>
        </Grid>
      </Grid>
      <Paper square className={classes.searchInput}>
        <Tabs
          variant="fullWidth"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="待审核" />
          <Tab label="发布中" />
          {/* <Tab label="进行中" /> */}
          <Tab label="已完成" />
        </Tabs>
        <DataGrid
          rows={projectRowData}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className={classes.DataGridMargin}
        />
      </Paper>

      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          查看项目
        </DialogTitle>
        <DialogContent dividers>
          <PublishProjectView
            showView={true}
            onClose={handleClose}
            editOpen={handleEditOpen}
          ></PublishProjectView>
        </DialogContent>
      </Dialog>
      <Dialog onClose={handleEditClose} open={editOpen} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleEditClose}>
          申请项目
        </DialogTitle>
        <DialogContent dividers>
          <ApplyingBiddingInfoView onClose={handleEditClose}></ApplyingBiddingInfoView>
        </DialogContent>
      </Dialog>
      <CancelCompanyPublishProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
        }}
      ></CancelCompanyPublishProjectDialog>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(companyProjectListView);
